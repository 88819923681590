import {
  deliveryInfoExcelDownLoad,
  getDeliveryInfo,
  getPaymentList,
  getReceipt,
  getScanning,
  notice,
  paymentExcelDownLoad,
  receiptExcelDownLoad,
  scanningExcelDownLoad,
  setPassword,
} from "@/api";
import { loginUser } from "@/api/auth";
import {
  getReleaseItem,
  getReleaseList,
  releaseExcelDownLoad,
} from "@/api/chulgo";
import {
  deliveryExcelDownLoad,
  fixDelivery,
  getDeliveryList,
  getDeliverySearch,
  setDelivery,
  deliveryConfirm,
  deliveryConfirmOk,
  deliveryConfirmCancel,
  deliveryMiConfirmOk,
  deliveryMiConfirmCancel,
} from "@/api/delivery";
import {
  fixItem,
  getCode,
  getFactSearch,
  getItemGroup,
  getItemList,
  getItemSearch,
  itemExcelDownLoad,
  setItem,
} from "@/api/itemmaster";
import {
  getMisdeliveredTotal,
  getMissendGap,
  misdeliveredTotalExcelDownLoad,
  missendGapExcelDownLoad,
} from "@/api/misdelivered";
import {
  getMissendConfirmList,
  getMissendList,
  getMissendNotConfirmList,
  getMissendTotalList,
  missendExcelDownLoad,
} from "@/api/missend";
import {
  containerSubsidy,
  fixOrder,
  getArrangement,
  getDeliveryDestination,
  getDeptSale,
  getOrderDetail,
  getOrderItemSearch,
  getOrderMaster,
  getQtyPstock,
  getReleaseCode,
  getSoldcdSearch,
  orderCreate,
  orderCreateConfirm,
  orderCreateConfirmCancel,
  orderCreateList,
  orderCreateListExcelDownLoad,
  orderCreateListTotal,
  orderMasterExcelDownLoad,
} from "@/api/order";
import {
  getIpgoCode,
  ipgoExpList,
  ipgoExpDetail,
  ipgoExpInsert,
  ipgoExpUpdate,
  ipgoExpDelete,
} from "@/api/ipgoexp";
import {
  InventoryExcelDownLoad,
  getInventoryStatus,
  getInventoryStatusDate,
  getInventoryStatusTotal,
} from "@/api/qty";

import {
  deleteAllLocalStorage,
  saveAuthToLocalStorage,
  saveCustToLocalStorage,
  saveUserToLocalStorage,
} from "@/utils/localStorage";
import store from ".";
import {
  bondedBLExcelDownLoad,
  bondedExcelDownLoad,
  getBondedInventoryStatus,
  getBondedInventoryStatusBL,
  getWarehouseCode,
} from "@/api/bonded";

export default {
  pushStack({ commit }, routeName) {
    commit("PUSH_STACK", routeName);
  },
  //로그인
  async LOGIN({ commit }, userData) {
    const { data } = await loginUser(userData);
    if (data.result === 1) {
      saveAuthToLocalStorage(data.session_key);
      saveUserToLocalStorage(
        JSON.stringify({
          user: data.user,
          dept: data.dept,
          cust: data.cust,
          center: data.center,
        })
      );
      saveCustToLocalStorage(
        JSON.stringify({
          cust_cd: data?.cust[0]?.cust_cd,
          cust_nm: data?.cust[0]?.cust_nm,
        })
      );
      commit("setToken", data.session_key);
    }
    return data;
  },

  //로그아웃
  async LOGOUT() {
    store.state.reLogin = true;
    deleteAllLocalStorage();
  },

  //공지사항
  async NOTICE() {
    const { data } = await notice();
    return data;
  },

  //패스워드 변경
  async SET_PASSWORD(state, pw) {
    const { data } = await setPassword(pw);
    return data;
  },

  //코드조회
  async GET_CODE(state, code) {
    const { data } = await getCode(code);
    return data;
  },

  /*상품 마스터 ---------------------------------------------------------------------------------------------- */
  //상품검색
  async GET_ITEM_SEARCH(state, search) {
    const { data } = await getItemSearch(search);
    return data;
  },

  //거래처검색
  async GET_FACT_SEARCH(state, search) {
    const { data } = await getFactSearch(search);
    return data;
  },

  //상품카테고리(그룹)조회
  async GET_ITEM_GROUP(state, grp) {
    const { data } = await getItemGroup(grp);
    return data;
  },

  //상품마스터 신규등록
  async SET_ITEM(state, item) {
    const { data } = await setItem(item);
    return data;
  },

  //상품조회
  async GET_ITEM_LIST(state, item) {
    const { data } = await getItemList(item);
    return data;
  },

  //상품조회 엑셀 다운로드
  async ITEM_EXCEL(state, obj) {
    const { data } = await itemExcelDownLoad(obj);
    return data;
  },

  //상품마스터 수정
  async FIX_ITEM(state, item) {
    const { data } = await fixItem(item);
    return data;
  },

  /*배송처마스터 ---------------------------------------------------------------------------------------------- */
  //배송처 신규등록
  async SET_DELIVERY(state, item) {
    const { data } = await setDelivery(item);
    return data;
  },

  async DELIVERY_CONFIRM(state, item) {
    const { data } = await deliveryConfirm(item);
    return data;
  },

  async DELIVERY_CONFIRM_OK(state, item) {
    const { data } = await deliveryConfirmOk(item);
    return data;
  },

  async DELIVERY_CONFIRM_CANCEL(state, item) {
    const { data } = await deliveryConfirmCancel(item);
    return data;
  },

  async DELIVERY_MICONFIRM_OK(state, item) {
    const { data } = await deliveryMiConfirmOk(item);
    return data;
  },

  async DELIVERY_MICONFIRM_CANCEL(state, item) {
    const { data } = await deliveryMiConfirmCancel(item);
    return data;
  },

  //배송처조회
  async GET_DELIVERY_LIST(state, item) {
    const { data } = await getDeliveryList(item);
    return data;
  },

  //배송처조회 엑셀 다운로드
  async DELIVERY_EXCEL(state, obj) {
    const res = await deliveryExcelDownLoad(obj);
    return res;
  },

  //배송처 검색
  async GET_DELIVERY_SEARCH(state, item) {
    const { data } = await getDeliverySearch(item);
    return data;
  },

  //배송처 수정
  async FIX_DELIVERY(state, item) {
    const { data } = await fixDelivery(item);
    return data;
  },

  /*입고내역조회 ---------------------------------------------------------------------------------------------- */
  async GET_RECEIPT(state, list) {
    const { data } = await getReceipt(list);
    return data;
  },

  //입고조회 엑셀 다운로드
  async RECEIPT_EXCEL(state, obj) {
    const { data } = await receiptExcelDownLoad(obj);
    return data;
  },

  /* 출고내역조회  ---------------------------------------------------------------------------------------------- */
  //출고조회-거래처
  async GET_RELEASE_LIST(state, list) {
    const { data } = await getReleaseList(list);
    return data;
  },

  //출고조회-상품
  async GET_RELEASE_ITEM(state, list) {
    const { data } = await getReleaseItem(list);
    return data;
  },

  //출고조회내역 엑셀다운로드 상품/거래처 공통
  async RELEASE_EXCEL(state, obj) {
    const { data } = await releaseExcelDownLoad(obj);
    return data;
  },

  /* 현재고  ---------------------------------------------------------------------------------------------- */
  //재고현황(유통기한)
  async GET_INVENTORY_STATUS(state, list) {
    const { data } = await getInventoryStatus(list);
    return data;
  },

  //재고현황(유통기한/제조일자)
  async GET_INVENTORY_STATUS_DATE(state, list) {
    const { data } = await getInventoryStatusDate(list);
    return data;
  },

  //재고현황 Total
  async GET_INVENTORY_STATUS_TOTAL(state, list) {
    const { data } = await getInventoryStatusTotal(list);
    return data;
  },

  //재고현황 엑셀 다운로드
  async INVENTORY_EXCEL(state, obj) {
    const { data } = await InventoryExcelDownLoad(obj);
    return data;
  },

  /*상품별 기간 수불 조회 ---------------------------------------------------------------------------------------------- */
  async GET_PAYMENT_LIST(state, list) {
    const { data } = await getPaymentList(list);
    return data;
  },

  //수불조회내역 엑셀다운로드
  async PAYMENT_EXCEL(state, obj) {
    const { data } = await paymentExcelDownLoad(obj);
    return data;
  },

  /* 미배송 반품 출고현황  ---------------------------------------------------------------------------------------------- */
  //미배송 반품조회(전표)
  async GET_MISSEND_LIST(state, list) {
    const { data } = await getMissendList(list);
    return data;
  },

  //미배송 반품조회(확인)
  async GET_MISSEND_CONFIRM_LIST(state, list) {
    const { data } = await getMissendConfirmList(list);
    return data;
  },

  //미배송 반품조회(미확인)
  async GET_MISSEND_NOT_CONFIRM_LIST(state, list) {
    const { data } = await getMissendNotConfirmList(list);
    return data;
  },

  //미배송 반품조회(전체)
  async GET_MISSEND_TOTAL_LIST(state, list) {
    const { data } = await getMissendTotalList(list);
    return data;
  },

  //미배송 반품조회 엑셀다운로드
  async MISSEND_EXCEL(state, obj) {
    const { data } = await missendExcelDownLoad(obj);
    return data;
  },

  /* 미송/오송 내역 ----------------------------------------------------------------------------- */
  //미송/오송 조회(차이)
  async GET_MISSEND_GAP(state, list) {
    const { data } = await getMissendGap(list);
    return data;
  },

  //미송/오송 조회(차이) 엑셀 다운로드
  async MISSEND_GAP_EXCEL(state, obj) {
    const { data } = await missendGapExcelDownLoad(obj);
    return data;
  },

  //미송/오송 조회(전체)
  async GET_MISSEND_TOTAL(state, list) {
    const { data } = await getMisdeliveredTotal(list);
    return data;
  },

  //미송/오송 조회(전체) 엑셀 다운로드
  async MISSEND_TOTAL_EXCEL(state, obj) {
    const { data } = await misdeliveredTotalExcelDownLoad(obj);
    return data;
  },

  /* 배송정보 조회 ----------------------------------------------------------------------------------------*/
  async GET_DELIVERY_INFO(state, list) {
    const { data } = await getDeliveryInfo(list);
    return data;
  },

  //배송처조회내역 엑셀다운로드
  async DELIVERY_INFO_EXCEL(state, obj) {
    const { data } = await deliveryInfoExcelDownLoad(obj);
    return data;
  },

  /* 주판서 스캔 이미지 조회 -------------------------------------------------------------------------------------------*/
  async GET_SCANNING(state, list) {
    const { data } = await getScanning(list);
    return data;
  },

  //스캔이미지 조회 엑셀다운로드
  async SCANNING_EXCEL(state, obj) {
    const { data } = await scanningExcelDownLoad(obj);
    return data;
  },

  /* 오더등록및조회(마감및취소)  ----------------------------------------------------------------------------------------*/
  //출고구분조회
  async GET_RELEASE_CODE(state, list) {
    const { data } = await getReleaseCode(list);
    return data;
  },

  //출고구분조회
  async GET_IPGO_CODE(state, list) {
    const { data } = await getIpgoCode(list);
    return data;
  },

  //배송처조회
  async GET_DELIVERY_DESTINATION(state, list) {
    const { data } = await getDeliveryDestination(list);
    return data;
  },

  //영업부서조회
  async GET_DEPT_SALE(state, list) {
    const { data } = await getDeptSale(list);
    return data;
  },

  //주문생성확정 - 취소
  async ORDER_CREATE_CONFIRM_CANCEL(state, list) {
    const { data } = await orderCreateConfirmCancel(list);
    return data;
  },

  //주문생성확정
  async ORDER_CREATE_CONFIRM(state, list) {
    const { data } = await orderCreateConfirm(list);
    return data;
  },

  //주문생성목록(전체)
  async ORDER_CREATE_LIST_TOTAL(state, list) {
    const { data } = await orderCreateListTotal(list);
    return data;
  },

  //주문생성목록(마감전/마감완료)
  async ORDER_CREATE_LIST(state, list) {
    const { data } = await orderCreateList(list);
    return data;
  },


  //주문생성목록(마감전/마감완료)
  async IPGOEXP_LIST(state, list) {
    const { data } = await ipgoExpList(list);
    return data;
  },
  //주문상세조회
  async IPGOEXP_DETAIL(state, list) {
    const { data } = await ipgoExpDetail(list);
    return data;
  },


  //주문생성
  async IPGOEXP_INSERT(state, list) {
    const { data } = await ipgoExpInsert(list);
    return data;
  },

  //주문수정
  async IPGOEXP_UPDATE(state, list) {
    const { data } = await ipgoExpUpdate(list);
    return data;
  },



  //주문수정
  async IPGOEXP_DELETE(state, list) {
    const { data } = await ipgoExpDelete(list);
    return data;
  },



  //주문 생성 목록 조회내역 엑셀다운로드
  async ORDER_CREATE_EXCEL(state, obj) {
    const { data } = await orderCreateListExcelDownLoad(obj);
    return data;
  },

  //주문생성
  async ORDER_CREATE(state, list) {
    const { data } = await orderCreate(list);
    return data;
  },

  //주문수정
  async FIX_ORDER(state, list) {
    const { data } = await fixOrder(list);
    return data;
  },

  //주문상세조회
  async GET_ORDER_DETAIL(state, list) {
    const { data } = await getOrderDetail(list);
    return data;
  },

  //용기보조금
  async CONTAINER_SUBSIDY(state, list) {
    const { data } = await containerSubsidy(list);
    return data;
  },

  //주문마스터조회
  async GET_ORDER_MASTER(state, list) {
    const { data } = await getOrderMaster(list);
    return data;
  },

  //주문마스터 조회내역 엑셀다운로드
  async ORDER_MASTER_EXCEL(state, obj) {
    const { data } = await orderMasterExcelDownLoad(obj);
    return data;
  },

  //배치조회
  async GET_ARRANGEMENT(state, list) {
    const { data } = await getArrangement(list);
    return data;
  },

  //주문을 위한 상품검색
  async GET_ORDER_ITEM_SEARCH(state, list) {
    const { data } = await getOrderItemSearch(list);
    return data;
  },

  //재고조회
  async GET_QTY_PSTOCK(state, list) {
    const { data } = await getQtyPstock(list);
    return data;
  },

  //판매처명 검색
  async GET_SOLDCD_SEARCH(state, list) {
    const { data } = await getSoldcdSearch(list);
    return data;
  },

  /* 보세재고 ---------------------------------------------------------------------------------------------------------- */
  //상품재고현황BL
  async GET_BONDED_INVENTORY_STATUS_BL(state, item) {
    const { data } = await getBondedInventoryStatusBL(item);
    return data;
  },

  //상품재고현황BL 엑셀다운로드
  async BONDED_BL_EXCEL(state, obj) {
    const { data } = await bondedBLExcelDownLoad(obj);
    return data;
  },

  //상품재고현황
  async GET_BONDED_INVENTORY_STATUS(state, item) {
    const { data } = await getBondedInventoryStatus(item);
    return data;
  },

  //상품재고현황 엑셀다운로드
  async BONDED_EXCEL(state, obj) {
    const { data } = await bondedExcelDownLoad(obj);
    return data;
  },

  //창고구분
  async GET_WAREHOUSE_CODE(state, center) {
    const { data } = await getWarehouseCode(center);
    return data;
  },
};
