<template>
  <div class="error">
    <h1 class="h">청도물류(주)</h1>
    <div class="errorPage">
      <not-found-page />
      <h2 class="errorPageTitle">{{ title }}</h2>
      <p class="errorPageContent" v-html="content"></p>
      <square-round-btn
        class="errorPageBtn"
        className="type3"
        :text="btnTxt"
        @btnEvent="back"
      />
    </div>
  </div>
</template>

<script>
import NotFoundPage from "@/components/common/styled/icon/error/NotFoundPage.vue";
import SquareRoundBtn from "@/components/common/styled/btn/SquareRoundBtn.vue";
export default {
  components: { NotFoundPage, SquareRoundBtn },
  data() {
    return {
      isOnLine: navigator.onLine,
      notFound: {
        title: "해당 페이지를 찾을 수 없습니다.",
        content: `이용에 불편을 드려 죄송합니다.<br> 빠르게 해당 오류를 해결 할 수 있도록 하겠습니다.`,
        btnTxt: "이전으로",
      },
      opLine: {
        title: "네트워크 연결을 확인 해주세요.",
        content: `네트워크 연결 상태가 좋지 않습니다.<br> 확인 후 다시 시도해주세요.`,
        btnTxt: "홈으로",
      },
    };
  },
  computed: {
    title() {
      return this.isOnLine ? this.notFound.title : this.opLine.title;
    },
    content() {
      return this.isOnLine ? this.notFound.content : this.opLine.content;
    },
    btnTxt() {
      return this.isOnLine ? this.notFound.btnTxt : this.opLine.btnTxt;
    },
  },
  mounted() {
    this.$store.state.loading = false;
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--vh, 1vh) * 100);
  .errorPageBtn {
    position: absolute;
    bottom: 80px;
    left: 50%;
    width: 196px;
    margin-left: -98px;
  }
  .errorPage {
    margin-top: -25vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .errorPageTitle {
      margin-top: 36px;
      @include boxTxtStyle($fw: 500, $fz: 24px, $lh: 29px, $c: #333);
    }
    .errorPageContent {
      margin-top: 12px;
      @include boxTxtStyle($fw: 500, $fz: 14px, $lh: 22px, $c: #767676);
    }
  }
}
</style>
