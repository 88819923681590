import { missend } from ".";
/* 미배송 반품 출고현황 */

//미배송 반품조회 엑셀다운로드
function missendExcelDownLoad(obj) {
  return missend.post("excel_download", obj);
}

//미배송 반품조회(전표)
function getMissendList(item) {
  return missend.post("missend_chit.seize", item);
}

//미배송 반품조회(확인)
function getMissendConfirmList(list) {
  return missend.post("missend_confirm.seize", list);
}

//미배송 반품조회(미확인)
function getMissendNotConfirmList(list) {
  return missend.post("missend_notconfirm.seize", list);
}

//미배송 반품조회(전체)
function getMissendTotalList(list) {
  return missend.post("missend_total.seize", list);
}

export {
  getMissendList,
  getMissendConfirmList,
  getMissendNotConfirmList,
  getMissendTotalList,
  missendExcelDownLoad,
};
