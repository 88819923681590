<template>
  <span class="icon">
    <svg
      width="42"
      height="31"
      viewBox="0 0 42 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.4357 22.4393C14.2641 23.6109 12.3646 23.6109 11.1931 22.4393L4.93064 16.1769C4.0046 15.2508 2.50318 15.2508 1.57714 16.1769C0.651097 17.1029 0.651097 18.6043 1.57714 19.5304L11.1931 29.1463C12.3646 30.3179 14.2641 30.3179 15.4357 29.1463L40.3819 4.20005C41.308 3.27401 41.308 1.7726 40.3819 0.846552C39.4559 -0.0794924 37.9545 -0.0794921 37.0284 0.846553L15.4357 22.4393Z"
        fill="#0687FD"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
