import { bonded } from ".";
/* 보세재고 */

//상품재고현황BL
function getBondedInventoryStatusBL(item) {
  return bonded.post("item_bose_bl.seize", item);
}

//상품재고현황BL 엑셀다운로드
function bondedBLExcelDownLoad(obj) {
  return bonded.post("bl_excel_download", obj);
}

//상품재고현황
function getBondedInventoryStatus(item) {
  return bonded.post("item_bose.seize", item);
}

//상품재고현황 엑셀다운로드
function bondedExcelDownLoad(item) {
  return bonded.post("excel_download", item);
}

//창고구분
function getWarehouseCode(center) {
  return bonded.post("warehouse.seize", center);
}

export {
  getBondedInventoryStatusBL,
  getBondedInventoryStatus,
  getWarehouseCode,
  bondedBLExcelDownLoad,
  bondedExcelDownLoad,
};
