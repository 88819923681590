import store from "@/store";
import { createWebHistory, createRouter } from "vue-router";

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //login
    {
      path: "/",
      name: "root",
      redirect: "/login",
      component: () => import("@/views/LoginView.vue"),
      meta: { homeTo: true },
      children: [
        {
          path: "/login",
          name: "login",
          component: () => import("@/views/LoginView.vue"),
        },
      ],
    },
    //home
    {
      path: "/home",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: { authRequired: true },
    },
    //사용자 패스워드 관리
    {
      path: "/system/userPw",
      name: "userPw",
      component: () => import("@/views/PwChangeView.vue"),
      meta: { authRequired: true },
    },
    //마스터 관리 - 배송처 마스터
    {
      path: "/master/shippingAddress",
      name: "shippingAddress",
      redirect: "/master/shippingAddress/1",
      component: () => import("@/views/master/ShippingAddressView.vue"),
      meta: { authRequired: true },
      children: [
        //배송처 목록
        {
          path: "/master/shippingAddress/:id",
          name: "shippingAddressMain",
          component: () =>
            import(
              "@/components/master/shippingAddress/ShippingAddressMain.vue"
            ),
        },
        //배송처 등록
        {
          path: "/master/shippingAddress/registration",
          name: "shippingAddressRegistration",
          component: () =>
            import(
              "@/components/master/shippingAddress/ShippingAddressRegistration.vue"
            ),
        },
      ],
    },
    //마스터 관리 - 상품 마스터
    {
      path: "/master/product",
      name: "product",
      redirect: "/master/product/1",
      component: () => import("@/views/ProductView.vue"),
      meta: { authRequired: true },
      children: [
        //상품 마스터 목록
        {
          path: "/master/product/:id",
          name: "productId",
          component: () =>
            import("@/components/master/productMaster/ProductMain.vue"),
        },
        //상품 마스터 등록
        {
          path: "/master/product/registration",
          name: "productRegistration",
          component: () =>
            import("@/components/master/productMaster/ProductRegistration.vue"),
        },
        {
          path: "/master/product/fix",
          name: "productFix",
          component: () =>
            import("@/components/master/productMaster/ProductRegistration.vue"),
        },
      ],
    },
    //입고관리
    {
      path: "/warehousing",
      name: "warehousing",
      redirect: "/warehousing/list/1",
      component: () => import("@/views/warehousing/WarehousingView.vue"),
      meta: { authRequired: true },
      children: [
        //상품 기간별 입고내역
        {
          path: "/warehousing/list/:id",
          name: "warehousing",
          component: () => import("@/views/warehousing/WarehousingView.vue"),
        },
      ],
    },
    
    //출고관리
    {
      path: "/release",
      name: "release",
      redirect: "/release/releaseList/1",
      component: () => import("@/views/release/ReleaseListView.vue"),
      meta: { authRequired: true },
      children: [
        //상품 기간별 출고내역
        {
          path: "/release/releaseList/:id",
          name: "releaseList",
          component: () =>
            import("@/components/release/ReleaseListContent.vue"),
        },
        //배송처 기간별 출고내역
        {
          path: "/release/releaseDeliveryList/:id",
          name: "releaseDeliveryList",
          component: () =>
            import("@/components/release/ReleaseListContent.vue"),
        },
      ],
    },
    //배송처 출고내역
    {
      path: "/shipmentDetails",
      name: "shipmentDetails",
      component: () => import("@/views/ShipmentDetailsView.vue"),
      meta: { authRequired: true },
    },
    //재고 관리 - 기간별 수불내역 조회
    {
      path: "/inventory/paymentsHistory",
      name: "paymentsHistory",
      redirect: "/inventory/paymentsHistory/1",
      component: () => import("@/views/inventory/ReceiptsAndPaymentsView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/inventory/paymentsHistory/:id",
          name: "paymentsHistoryId",
          component: () =>
            import("@/views/inventory/ReceiptsAndPaymentsView.vue"),
        },
      ],
    },
    //재고 관리 - 상품별 재고현황(현재고)
    {
      path: "/inventory/qty",
      name: "qty",
      redirect: "/inventory/qty/1",
      component: () => import("@/views/inventory/QtyView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/inventory/qty/:id",
          name: "qtyId",
          component: () => import("@/views/inventory/QtyView.vue"),
        },
      ],
    },
    //실적 관리 - 배송정보 조회
    {
      path: "/pm/shipmentInquiry",
      name: "shipmentInquiry",
      redirect: "/pm/shipmentInquiry/1",
      component: () => import("@/views/pm/ShipmentInquiryView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/shipmentInquiry/:id",
          name: "shipmentInquiryId",
          component: () => import("@/views/pm/ShipmentInquiryView.vue"),
        },
      ],
    },

    //실적 관리 - 배송확정관리
    {
      path: "/pm/shipmentConfirm",
      name: "shipmentConfirm",
      redirect: "/pm/shipmentConfirm/1",
      component: () => import("@/views/pm/ShipmentConfirmView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/shipmentConfirm/:id",
          name: "shipmentConfirmId",
          component: () => import("@/views/pm/ShipmentConfirmView.vue"),
        },
      ],
    },

    //실적 관리 - 빠른배송확정
    {
      path: "/pm/shipmentConfirm2",
      name: "shipmentConfirm2",
      redirect: "/pm/shipmentConfirm2/1",
      component: () => import("@/views/pm/ShipmentConfirmView2.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/shipmentConfirm2/:id",
          name: "shipmentConfirmId2",
          component: () => import("@/views/pm/ShipmentConfirmView2.vue"),
        },
      ],
    },

    //실적 관리 - 주판서 스캔 이미지 조회
    {
      path: "/pm/getScanImage",
      name: "getScanImage",
      redirect: "/pm/getScanImage/1",
      component: () => import("@/views/pm/GetScanImageView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/getScanImage/:id",
          name: "getScanImageId",
          component: () => import("@/views/pm/GetScanImageView.vue"),
        },
      ],
    },
    //실적 관리 - 미송/오송 내역
    {
      path: "/pm/misdelivery",
      name: "misdelivery",
      redirect: "/pm/misdelivery/1",
      component: () => import("@/views/pm/MisdeliveryHistoryView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/misdelivery/:id",
          name: "misdeliveryId",
          component: () => import("@/views/pm/MisdeliveryHistoryView.vue"),
        },
      ],
    },
    //실적 관리 - 미배송 반품 출고현황
    {
      path: "/pm/return",
      name: "return",
      redirect: "/pm/return/1",
      component: () => import("@/views/pm/ReturnView.vue"),
      meta: { authRequired: true },
      children: [
        {
          path: "/pm/return/:id",
          name: "returnId",
          component: () => import("@/views/pm/ReturnView.vue"),
        },
      ],
    },
    //오더관리 - 오더 등록 및 조회
    {
      path: "/order/create",
      name: "orderCreate",
      redirect: "/order/create/1",
      component: () => import("@/views/order/CreateADraftOrderAndHistory.vue"),
      meta: { authRequired: true },
      children: [
        // 오더 목록 조회
        {
          path: "/order/create/:id",
          name: "orderCreateList",
          component: () =>
            import("@/components/order/OrderSkedHistoryContents.vue"),
        },
      ],
    },

    //입고예정 - 입고예정 등록 및 조회
    {
      path: "/ipgo/create",
      name: "ipgoCreate",
      redirect: "/ipgo/create/1",
      component: () => import("@/views/ipgo/CreateIpgoAndHistory.vue"),
      meta: { authRequired: true },
      children: [
        // 오더 목록 조회
        {
          path: "/ipgo/create/:id",
          name: "ipgoCreateList",
          component: () =>
            import("@/components/ipgo/IpgoHistoryContents.vue"),
        },
      ],
    },
    //오더 마감 및 취소
    {
      path: "/order/main",
      name: "orderMain",
      redirect: "/order/sked/1",
      component: () => import("@/views/order/OrderCloseNCancelView.vue"),
      meta: { authRequired: true },
      children: [
        // 오더 예정
        {
          path: "/order/sked/:id",
          name: "orderScheduled",
          component: () =>
            import("@/components/order/OrderSkedHistoryContents.vue"),
        },
        // 오더처리 내역
        {
          path: "/order/history/:id",
          name: "orderHistory",
          component: () =>
            import("@/components/order/OrderSkedHistoryContents.vue"),
        },
        // 오더처리 전체
        {
          path: "/order/total/:id",
          name: "orderTotal",
          component: () =>
            import("@/components/order/OrderSkedHistoryContents.vue"),
        },
      ],
    },
    //보세 재고
    {
      path: "/bonded/main",
      name: "bondedMain",
      redirect: "/bonded/status/1",
      component: () => import("@/views/bonded/BondedView.vue"),
      meta: { authRequired: true },
      children: [
        // 상품재고현황
        {
          path: "/bonded/status/:id",
          name: "bondedStatus",
          component: () => import("@/components/bonded/BondedContents.vue"),
        },
        // BL별 상품재고현황
        {
          path: "/bonded/bl/:id",
          name: "bondedBL",
          component: () => import("@/components/bonded/BondedContents.vue"),
        },
      ],
    },
    {
      path: "/:pathMatch(.*)",
      name: "not-found",
      component: () => import("@/views/ErrorView.vue"),
    },
  ],
  scrollBehavior() {
    return { top: 0 };
  },
});

router.afterEach((to, from) => {
  if (to.name !== from.name) {
    store.dispatch("pushStack", to.name);
  }
});

window.popStateDetected = false;

router.beforeEach(function (to, from, next) {
  if (
    !store.state.token &&
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    alert("권한이 없습니다. 로그인 해주세요.");
    store.state.loading = true;
    router.push("/login");
  } else if (
    to.matched.some(function (routeInfo) {
      return routeInfo.meta.homeTo;
    }) &&
    store.state.token &&
    !store.state.reLogin
  ) {
    router.push("/home");
    if (store.state.reLogin) next();
  } else {
    next();
  }
});

export default router;
