import { misdelivered } from ".";
/* 미송 / 오송 내역 */

//미송/오송 조회(차이)
function getMissendGap(list) {
  return misdelivered.post("missend_gap.seize", list);
}

//미송/오송 조회(차이) 엑셀 다운로드
function missendGapExcelDownLoad(obj) {
  return misdelivered.post("gap_excel_download", obj);
}

//미송/오송 조회(전체)
function getMisdeliveredTotal(list) {
  return misdelivered.post("missend_total.seize", list);
}

//미송/오송 조회(전체) 엑셀 다운로드
function misdeliveredTotalExcelDownLoad(obj) {
  return misdelivered.post("total_excel_download", obj);
}

export {
  getMissendGap,
  getMisdeliveredTotal,
  missendGapExcelDownLoad,
  misdeliveredTotalExcelDownLoad,
};
