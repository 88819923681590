import { order } from ".";

/* 오더등록및조회(마감및취소) */

//출고구분조회
function getReleaseCode() {
  return order.post("chulgo_gb.seize", {});
}

//배송처조회
function getDeliveryDestination() {
  return order.post("delivery.seize", {});
}

//영업부서조회
function getDeptSale() {
  return order.post("dept_sale.seize", {});
}

//주문생성확정 - 취소
function orderCreateConfirmCancel(item) {
  return order.post("order_create_confirm_cancel.seize", item);
}

//주문생성확정
function orderCreateConfirm(item) {
  return order.post("order_create_confirm.seize", item);
}

//주문생성목록(전체)
function orderCreateListTotal(item) {
  return order.post("order_create_list_total.seize", item);
}

//주문생성목록(마감전/마감완료)
function orderCreateList(item) {
  return order.post("order_create_list.seize", item);
}



//주문 생성 목록 조회내역 엑셀다운로드
function orderCreateListExcelDownLoad(obj) {
  return order.post("order_excel_download", obj);
}

//주문생성
function orderCreate(item) {
  return order.post("order_create.seize", item);
}

//주문상세조회
function getOrderDetail(item) {
  return order.post("order_detail.seize", item);
}

//용기보조금
function containerSubsidy(item) {
  return order.post("order_item_container.seize", item);
}

//배치조회
function getArrangement(item) {
  return order.post("order_item_lot.seize", item);
}

//주문을 위한 상품검색
function getOrderItemSearch(item) {
  return order.post("order_item_search.seize", item);
}

//주문마스터조회
function getOrderMaster(item) {
  return order.post("order_master.seize", item);
}

//주문마스터 조회내역 엑셀다운로드
function orderMasterExcelDownLoad(obj) {
  return order.post("excel_download", obj);
}

//재고조회
function getQtyPstock(item) {
  return order.post("qty_pstock.seize", item);
}

//판매처명 검색
function getSoldcdSearch(search) {
  return order.post("soldcd_search.seize", search);
}

//주문수정
function fixOrder(search) {
  return order.post("order_modify.seize", search);
}

export {
  getReleaseCode,
  getDeliveryDestination,
  getDeptSale,
  orderCreateConfirmCancel,
  orderCreateConfirm,
  orderCreateListTotal,
  orderCreateList,
  orderCreate,
  getOrderDetail,
  containerSubsidy,
  getArrangement,
  getOrderItemSearch,
  getOrderMaster,
  getQtyPstock,
  getSoldcdSearch,
  fixOrder,
  orderCreateListExcelDownLoad,
  orderMasterExcelDownLoad,
};
