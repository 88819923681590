import { delivery } from ".";
/* 배송처마스터 */

//배송처 신규등록
function setDelivery(item) {
  return delivery.post("delivery_insert.seize", item);
}

function deliveryConfirm(item) {
  return delivery.post("delivery_confirm.seize", item);
}


function deliveryConfirmOk(item) {
  return delivery.post("delivery_confirm_ok.seize", item);
}

function deliveryConfirmCancel(item) {
  return delivery.post("delivery_confirm_cancel.seize", item);
}

function deliveryMiConfirmOk(item) {
  return delivery.post("delivery_miconfirm_ok.seize", item);
}

function deliveryMiConfirmCancel(item) {
  return delivery.post("delivery_miconfirm_cancel.seize", item);
}

//배송처 조회
function getDeliveryList(list) {
  return delivery.post("delivery_list.seize", list);
}

//배송처조회 엑셀 다운로드
function deliveryExcelDownLoad(obj) {
  return delivery.post("excel_download", obj);
}

//배송처 검색
function getDeliverySearch(search) {
  return delivery.post("delivery_search.seize", search);
}

//배송처 수정
function fixDelivery(item) {
  return delivery.post("delivery_update.seize", item);
}

export {
  setDelivery,
  deliveryConfirm,
  deliveryConfirmOk,
  deliveryConfirmCancel,
  deliveryMiConfirmOk,
  deliveryMiConfirmCancel,
  getDeliveryList,
  getDeliverySearch,
  fixDelivery,
  deliveryExcelDownLoad,
};
