<template>
  <transition name="popupAni">
    <div class="popup" v-if="show">
      <div class="popupBg" @click="close"></div>

      <div :class="`popupContent ${type}`">
        <!-- default popup -->
        <div class="type1">
          <check-icon v-if="iconType === 'check'" />
          <fail-icon v-if="iconType === 'fail'" />
          <fix-icon v-if="iconType === 'fix'" />
          <h3 class="title">{{ title }}</h3>
          <p class="txt" v-if="!vhtml">{{ text }}</p>
          <p class="txt html" v-if="vhtml" v-html="text"></p>
          <round-btn :link="link" :text="btnTxt" @click="close(link)" />
        </div>
        <slot name="content" v-if="type === 'content'"></slot>
      </div>
    </div>
  </transition>
</template>

<script>
import RoundBtn from "../btn/RoundBtn.vue";
import CheckIcon from "../icon/popup/CheckIcon.vue";
import FailIcon from "../icon/popup/FailIcon.vue";
import FixIcon from "../icon/popup/FixIcon.vue";

export default {
  components: { RoundBtn, CheckIcon, FixIcon, FailIcon },
  props: {
    type: {
      type: String,
      default: "type1",
    },
    title: String,
    text: String,
    btnTxt: String,
    link: {
      type: String,
      default: null,
    },
    vhtml: Boolean,
    iconType: String,
  },
  data() {
    return {
      show: false,
    };
  },
  mounted() {
    document.querySelector("html").style.overflowY = "hidden";
    document.body.style.overflowY = "hidden";
  },
  beforeUnmount() {
    document.querySelector("html").style.overflowY = "auto";
    document.body.style.overflowY = "auto";
  },
  methods: {
    close(link) {
      if (link === "close") {
        this.popupHide();
      } else if (link === "/home") {
        this.$store.state.loading = true;
        this.$emit("close");
      } else {
        this.$emit("close");
      }
    },
    popupHide() {
      this.show = false;
    },
    popupShow() {
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
  .btnWrap .btn {
    width: auto;
    min-width: 183px;
  }

  .popupBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba($color: #101623, $alpha: 0.3);
  }

  .popupContent {
    position: relative;
    padding: 0 24px;
    width: 100%;

    .type1 {
      display: flex;
      align-items: center;
      flex-direction: column;
      background: #fff;
      padding: 60px 38px 38px;
      border-radius: 24px;
      max-width: 327px;
      margin: auto;

      .btnWrap {
        margin-bottom: 0;
      }

      .title {
        display: block;
        @include boxTxtStyle($fz: 24px, $lh: 32px, $fw: 500, $c: #101623);

        &.imageFailTitle {
          color: #fc6969;
        }
      }

      .txt {
        @include boxTxtStyle($fz: 16px, $lh: 24px, $c: #a1a8b0, $ls: 0.5px);
        margin-bottom: 48px;
      }

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 102px;
        height: 102px;
        background: #f5f8ff;
        border-radius: 50%;
        margin-bottom: 40px;
        &.cancelIcon {
          margin-bottom: 20px;
        }
      }
    }

    .contentPopup {
      height: 100%;
      overflow-y: auto;
    }
  }
}

.popupAni-enter-active {
  transition: 0.5s ease;
  .popupBg {
    transition: opacity 0.5s ease;
    opacity: 1;
  }
  .type1 {
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease;
  }
}

.popupAni-leave-active {
  .popupBg {
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  .type1 {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.5s ease;
  }
}

.popupAni-enter-from,
.popupAni-leave-to {
  transition: 0.5s ease;
  .popupBg {
    transition: opacity 0.5s ease;
    opacity: 0;
  }
  .type1 {
    opacity: 0;
    transform: translateY(30px);
    transition: transform 0.5s ease;
  }
}
</style>
