import { qty } from ".";
/* 현재고 */

//재고현황 엑셀 다운로드
function InventoryExcelDownLoad(item) {
  return qty.post("excel_download", item);
}

//재고현황(유통기한)
function getInventoryStatus(item) {
  return qty.post("qty_made_list.seize", item);
}

//재고현황(유통기한/제조일자)
function getInventoryStatusDate(item) {
  return qty.post("qty_make_list.seize", item);
}

//재고현황 Total
function getInventoryStatusTotal(total) {
  return qty.post("qty_total_list.seize", total);
}

export {
  getInventoryStatus,
  getInventoryStatusDate,
  getInventoryStatusTotal,
  InventoryExcelDownLoad,
};
