import { itemmaster } from ".";
/* 상품마스터 */

//코드조회
function getCode(code) {
  return itemmaster.post("code.seize", code);
}

//상품조회 엑셀 다운로드
function itemExcelDownLoad(obj) {
  return itemmaster.post("excel_download", obj);
}

//거래처검색
function getFactSearch(search) {
  return itemmaster.post("fact_search.seize", search);
}

//상품검색
function getItemSearch(search) {
  return itemmaster.post("item_search.seize", search);
}

//상품카테고리(그룹)조회
function getItemGroup(grp) {
  return itemmaster.post("itemgroup.seize", grp);
}

//상품마스터 신규등록
function setItem(item) {
  return itemmaster.post("itemmaster_insert.seize", item);
}

//상품조회
function getItemList(item) {
  return itemmaster.post("itemmaster_list.seize", item);
}

//상품마스터 수정
function fixItem(item) {
  return itemmaster.post("itemmaster_update.seize", item);
}

export {
  getCode,
  getItemSearch,
  getItemGroup,
  setItem,
  getItemList,
  fixItem,
  getFactSearch,
  itemExcelDownLoad,
};
