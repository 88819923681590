import { createApp } from "vue";
import App from "./App";
import router from "./router/router";
import store from "./store";
import VCalendar from "v-calendar";
import { formatDate, formatTime } from "@/utils/filter";
const app = createApp(App);

window.app = app.use(VCalendar).use(router).use(store).mount("#app");

app.config.globalProperties.$filters = {
  formatDate,
  formatTime,
};
