import { chulgo } from ".";
/* 출고내역조회 */

//출고조회-거래처
function getReleaseList(list) {
  return chulgo.post("chulgo_delivery_list.seize", list);
}

//출고조회-상품
function getReleaseItem(item) {
  return chulgo.post("chulgo_item_list.seize", item);
}

//출고조회내역 엑셀다운로드 상품/거래처 공통
function releaseExcelDownLoad(obj) {
  return chulgo.post("excel_download", obj);
}

export { getReleaseList, getReleaseItem, releaseExcelDownLoad };
