<template>
  <div class="loading">
    <spinner-style />
  </div>
</template>

<script>
import SpinnerStyle from "@/components/common/styled/loading/SpinnerStyle.vue";
export default {
  components: { SpinnerStyle },
};
</script>

<style lang="scss" scoped>
.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba($color: #101623, $alpha: 0.4);
  z-index: 3000;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
