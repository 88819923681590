import Vuex from "vuex";
import mutations from "@/store/mutations";
import actions from "@/store/acitons";
import {
  getAuthFromLocalStorage,
  getCustFromLocalStorage,
  getUserFromLocalStorage,
} from "@/utils/localStorage";

const user = JSON.parse(getUserFromLocalStorage());
const cust = JSON.parse(getCustFromLocalStorage());
const store = new Vuex.Store({
  namespaced: true,
  state: {
    stack: [],
    token: getAuthFromLocalStorage(),
    loading: false,
    error: null,
    reLogin: null,
    user: user?.user,
    dept: user?.dept,
    cust: user?.cust,
    center: user?.center,
    ipgoexpLoad:null,
    orderTable: null,
    orderTableDetail: null,
    orderTableDetailCreate: null,
    orderRemark: null,
    productSelect: null,
    deliverySelect: null,
    orderSelectNum: null,
    centerSelect: {
      center_cd: user?.center[0]?.center_cd,
      center_nm: user?.center[0]?.center_nm,
    },
    custSelect: {
      cust_cd: cust?.cust_cd,
      cust_nm: cust?.cust_nm,
    },
    deptSelect: {
      dept_cd: user?.dept ? user?.dept[0]?.dept_cd : "",
      dept_nm: user?.dept ? user?.dept[0]?.dept_nm : "",
    },
    common: {
      user: {
        user_id: user?.user.user_id,
        user_nm: user?.user.user_nm,
        user_pwd: user?.user.user_pwd,
        email: user?.user.email,
      },
      center: user?.center.map((list) => {
        return {
          base_center_cd: list.base_center_cd,
          center_cd: list.center_cd,
          center_nm: list.center_nm,
        };
      }),
      cust: user?.cust.map((list) => {
        return {
          cust_cd: list.cust_cd,
          cust_div: list.cust_div,
          cust_nm: list.cust_nm,
        };
      }),
      dept: user?.dept?.map((list) => {
        return {
          dept_cd: list.dept_cd,
          deal_div: list.deal_div,
          dept_nm: list.dept_nm,
        };
      }),
    },
  },
  watch: {
    user: function () {
      if (user() /* function to detect if localstorage is supported*/) {
        JSON.parse(getUserFromLocalStorage()).user;
      }
    },
  },
  mutations,
  actions,
  getters: {
    lastDiff: (state) => {
      const reversed = state.stack.slice().reverse();
      const diff = reversed.findIndex((route) => route !== reversed[0]);
      return -1 * diff;
    },
    cust_cd: (state) => state.custSelect?.cust_cd,
  },
});

export default store;
