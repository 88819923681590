const token = process.env.VUE_APP_TOKEN_KEY;
const user = process.env.VUE_APP_USER_KEY;
const cust = process.env.VUE_APP_CUST_KEY;
function saveAuthToLocalStorage(value) {
  localStorage.setItem(token, value);
}

function saveUserToLocalStorage(value) {
  localStorage.setItem(user, value);
}

function saveCustToLocalStorage(value) {
  localStorage.setItem(cust, value);
}

function getAuthFromLocalStorage() {
  return localStorage.getItem(token);
}

function getUserFromLocalStorage() {
  return localStorage.getItem(user);
}

function getCustFromLocalStorage() {
  return localStorage.getItem(cust);
}

function deleteLocalStorage(value) {
  localStorage.removeItem(value);
}

function deleteAllLocalStorage() {
  localStorage.removeItem(user);
  localStorage.removeItem(token);
}
export {
  saveAuthToLocalStorage,
  saveUserToLocalStorage,
  getAuthFromLocalStorage,
  getUserFromLocalStorage,
  deleteLocalStorage,
  deleteAllLocalStorage,
  saveCustToLocalStorage,
  getCustFromLocalStorage,
};
