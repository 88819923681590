<template>
  <button class="squareRoundBtn" :class="className" @click.prevent="btnEvent">
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    text: String,
    className: String,
  },
  methods: {
    btnEvent() {
      this.$emit("btnEvent");
    },
  },
};
</script>

<style lang="scss" scoped>
.squareRoundBtn {
  display: inline-block;
  padding: 0 16px;
  background: $mainColor;
  height: 32px;
  border-radius: 8px;
  @include boxTxtStyle($fw: 500, $fz: 14px, $lh: 32px, $c: #fff);

  &.active {
    background: $mainColor;
    color: #fff;
  }
  &.type1 {
    padding: 0;
    background-color: #fff;
    border: none;
    color: $mainColor;
  }
  &.type2 {
    display: inline-block;
    min-width: 120px;
    margin-bottom: 12px;
    height: 52px;
    background: #007aff;
    border-radius: 10px;
    @include boxTxtStyle($ff: $interFont, $fz: 14px, $lh: 52px, $c: #fff);
  }
  &.type3 {
    display: inline-block;
    min-width: 196px;
    height: 48px;
    background: $mainColor;
    border-radius: 8px;
    text-align: center;
    @include boxTxtStyle($fw: 700, $fz: 18px, $lh: 48px, $c: #fff);
  }
  &.filterType1 {
    display: inline-block;
    min-width: 130px;
    height: 44px;
    background: #fff;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    text-align: center;
    @include boxTxtStyle($fw: 500, $fz: 16px, $lh: 19px, $c: #999);
  }
  &.filterType2 {
    display: inline-block;
    min-width: 130px;
    height: 44px;
    background: $mainColor;
    border: 1px solid $mainColor;
    border-radius: 8px;
    text-align: center;
    @include boxTxtStyle($fw: 700, $fz: 18px, $lh: 21px, $c: #fff);
  }
}
</style>
