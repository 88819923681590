<template>
  <span class="errorIcon">
    <svg width="100" height="100" viewBox="0 0 100 100" fill="none">
      <circle cx="50" cy="50" r="50" fill="#DBDBDB" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M50 81.25C41.6146 74.1146 35.3516 67.487 31.2109 61.3672C27.0703 55.2474 25 49.5833 25 44.375C25 36.5625 27.513 30.3385 32.5391 25.7031C37.5651 21.0677 43.3854 18.75 50 18.75C56.6146 18.75 62.4349 21.0677 67.4609 25.7031C72.487 30.3385 75 36.5625 75 44.375C75 49.5833 72.9297 55.2474 68.7891 61.3672C64.6484 67.487 58.3854 74.1146 50 81.25ZM50 57.8125C51.4382 57.8125 52.6042 56.6466 52.6042 55.2083C52.6042 53.7701 51.4382 52.6042 50 52.6042C48.5618 52.6042 47.3958 53.7701 47.3958 55.2083C47.3958 56.6466 48.5618 57.8125 50 57.8125ZM47.3958 32.2917C47.3958 30.8534 48.5618 29.6875 50 29.6875C51.4382 29.6875 52.6042 30.8534 52.6042 32.2917V45.8333C52.6042 47.2716 51.4382 48.4375 50 48.4375C48.5618 48.4375 47.3958 47.2716 47.3958 45.8333V32.2917Z"
        fill="white"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.errorIcon {
  display: flex;
}
</style>
