import store from "@/store/index";
import {
  deleteAllLocalStorage,
  getAuthFromLocalStorage,
  getCustFromLocalStorage,
} from "@/utils/localStorage";

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    function (config) {
      const token = getAuthFromLocalStorage();
      const cust = JSON.parse(getCustFromLocalStorage());
      if (token) {
        config.headers["session"] = token;
      }
      config.headers = config.headers ?? {};
      if (config.data instanceof FormData) {
        config.headers["Content-Type"] = "multipart/form-data";
      } else {
        config.headers["Content-Type"] = "application/json";
      }
      config.headers.Authorization = store.state.token;
      config.headers["Access-Control-Allow-Origin"] = "*";
      config.headers["cust_cd"] = cust?.cust_cd;
      config.timeout = 300000;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      const originalRequest = response.config;
      if (response.headers["content-disposition"]) {
        const name =
          response.headers["content-disposition"]?.split("filename=")[1];

        //IE11에서 blob처리 오류로 인해 분기처리
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          const blob = response.data;
          window.navigator.msSaveOrOpenBlob(blob, name);
        } else {
          //IE 이외 다운로드 처리
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", name);
          document.body.appendChild(link);
          link.click();
        }
      }
      if (
        response.data.result === -99 &&
        !originalRequest._retry &&
        !store.state.reLogin
      ) {
        deleteAllLocalStorage();
        store.state.reLogin = true;
      }
      return response;
    },
    async function (error) {
      if (error.response.data.result === -99) {
        // store.state.reLogin = true;
      }
      return Promise.reject(error);
    }
  );
  return instance;
}
