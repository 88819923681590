<template>
  <span class="icon">
    <svg
      width="46"
      height="37"
      viewBox="0 0 46 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.5799 1.99994L14.9154 1.99994C7.78616 1.99993 2.0001 7.78599 2.0001 14.9152C2.00009 22.0445 7.78615 27.8306 14.9154 27.8306L43.3291 27.8306"
        stroke="#FC6969"
        stroke-width="4"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M37.3874 21.3732L44 27.9859L37.3874 34.5985"
        stroke="#FC6969"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </span>
</template>

<script>
export default {};
</script>

<style></style>
