export default {
  //token
  setToken(state, token) {
    state.token = token;
  },
  clearToken(state) {
    state.token = "";
  },
  PUSH_STACK(state, routeName) {
    state.stack.push(routeName);
  },
};
