import axios from "axios";
import { setInterceptors } from "./common/interceptors";

function creacteInstance() {
  return axios.create({
    baseURL: process.env.VUE_APP_API_URL,
  });
}

function creacteInstanceWithAuth(url) {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}${url}`,
  });
  return setInterceptors(instance);
}

//login
export const instance = creacteInstance();

//패스워드 변경
export const setPassword = (data) => {
  return creacteInstanceWithAuth("password/").post(
    "change_password.seize",
    data,
  );
};

//상품 마스터
export const itemmaster = creacteInstanceWithAuth("itemmaster/");

//배송처 마스터
export const delivery = creacteInstanceWithAuth("delivery/");

/*입고내역조회*/

//입고조회
export const getReceipt = (list) => {
  return creacteInstanceWithAuth("ipgo/").post("ipgo_list.seize", list);
};

//입고조회 엑셀 다운로드
export const receiptExcelDownLoad = (obj) => {
  return creacteInstanceWithAuth("ipgo/").post("excel_download", obj);
};

//출고내역조회
export const chulgo = creacteInstanceWithAuth("chulgo/");

//현재고
export const qty = creacteInstanceWithAuth("qty/");

/*상품별 기간 수불 조회*/

//조회
export const getPaymentList = (list) => {
  return creacteInstanceWithAuth("ipgochulgo/").post(
    "ipgochulgo_list.seize",
    list,
  );
};

//수불조회내역 엑셀다운로드
export const paymentExcelDownLoad = (obj) => {
  return creacteInstanceWithAuth("ipgochulgo/").post(
    "excel_download.seize",
    obj,
  );
};

//미배송 반품 출고현황
export const missend = creacteInstanceWithAuth("chulgo_missend/");

//미송 / 오송 내역
export const misdelivered = creacteInstanceWithAuth("chulgo_missend2/");

/*배송정보 조회*/

//배송정보 조회
export const getDeliveryInfo = (list) => {
  return creacteInstanceWithAuth("delivery_info/").post(
    "delivery_info_list.seize",
    list,
  );
};

//배송처조회내역 엑셀다운로드
export const deliveryInfoExcelDownLoad = (obj) => {
  return creacteInstanceWithAuth("delivery_info/").post("excel_download", obj);
};

/*주판서 스캔 이미지 조회 */

//주판서 스캔 이미지 조회
export const getScanning = (scanning) => {
  return creacteInstanceWithAuth("scanbill/").post(
    "scanbill_list.seize",
    scanning,
  );
};

//스캔이미지 조회 엑셀다운로드
export const scanningExcelDownLoad = (obj) => {
  return creacteInstanceWithAuth("scanbill/").post("excel_download", obj);
};

//오더등록및조회(마감및취소)
export const order = creacteInstanceWithAuth("order/");

//오더등록및조회(마감및취소)
export const ipgoexp = creacteInstanceWithAuth("ipgoexp/");

//공지사항
export const notice = () => {
  return creacteInstanceWithAuth("info/").post("info_list.seize", {});
};

//보세재고
export const bonded = creacteInstanceWithAuth("bose/");
