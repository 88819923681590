<template>
  <div class="spinner">
    <span>
      <img :src="require(`@/assets/img/loading.png`)" alt="로딩이미지" />
    </span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.spinner {
  display: flex;
  span {
    animation: bg 1s linear infinite;
    img {
      width: 36px;
      height: 36px;
    }
  }
}

@keyframes bg {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
