<template>
  <div>
    <router-view v-if="isOnLine" />
    <loading-spinner v-if="$store.state.loading" />
    <error-view v-if="!isOnLine" />
    <popup-style
      ref="popup"
      :title="popupObj.title"
      :text="popupObj.text"
      :btnTxt="popupObj.btnTxt"
      :link="popupObj.link"
      :iconType="popupObj.iconType"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/common/styled/loading/LoadingSpinner.vue";
import ErrorView from "./views/ErrorView.vue";
import {
  getCustFromLocalStorage,
  getUserFromLocalStorage,
} from "./utils/localStorage";
import PopupStyle from "./components/common/styled/popup/PopupStyle.vue";
import { useRouter } from "vue-router";

export default {
  components: { LoadingSpinner, ErrorView, PopupStyle },
  data() {
    return {
      router: useRouter(),
      isOnLine: navigator.onLine,
      reset: false,
      popupObj: {
        title: "다시로그인",
        text: "세션 만료,다시 로그인 해주세요.",
        btnTxt: "확인",
        link: "/login",
        iconType: "fail",
      },
    };
  },
  watch: {
    "$store.state.reLogin": function () {
      if (this.$store.state.reLogin === true) {
        if (this.$route.path === "/login") {
          this.$refs.popup.popupHide();
        } else {
          this.$refs.popup.popupShow();
        }
      } else if (this.$store.state.reLogin === false) {
        if (this.$route.path === "/login") {
          this.$refs.popup.popupHide();
        }
      }
    },
    $route() {
      this.onLoad();
      if (this.$route.path === "/login") {
        this.$refs.popup.popupHide();
      }
    },
  },
  mounted() {
    document.querySelector("html").style.overflow = "auto";
    document.body.style.overflow = "auto";
    this.setOneVh();
    window.addEventListener("popstate", () => {
       this.$router.push("/home")
    });
    window.addEventListener("resize", this.setOneVh);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.backRoute);
  },
  methods: {
    setOneVh() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    },
    backRoute() {
      if (window.popStateDetected) {
        window.popStateDetected = true;
        if (this.$route.fullPath.includes("?")) {
          this.$store.state.loading = false;
          this.router.push({
            name: this.$store.state.stack[
              this.$store.state.stack.length + this.$store.getters.lastDiff - 1
            ],
          });
        }
      }
    },
    onLoad() {
      const user = JSON.parse(getUserFromLocalStorage());
      const cust = JSON.parse(getCustFromLocalStorage());
      this.$store.state.user = user?.user;
      this.$store.state.dept = user?.dept;
      this.$store.state.cust = user?.cust;
      this.$store.state.center = user?.center;

      this.$store.state.centerSelect =
        {
          center_cd: user?.center[0]?.center_cd,
          center_nm: user?.center[0]?.center_nm,
        } || "";
      this.$store.state.custSelect =
        {
          cust_cd: cust?.cust_cd,
          cust_nm: cust?.cust_nm,
        } || "";
      (this.$store.state.deptSelect =
        {
          dept_cd: user?.dept ? user?.dept[0]?.dept_cd : "",
          dept_nm: user?.dept ? user?.dept[0]?.dept_nm : "",
        } || ""),
        (this.$store.state.common = {
          user: {
            user_id: user?.user.user_id,
            user_nm: user?.user.user_nm,
            user_pwd: user?.user.user_pwd,
            email: user?.user.email,
          },
          center: user?.center.map((list) => {
            return {
              base_center_cd: list.base_center_cd,
              center_cd: list.center_cd,
              center_nm: list.center_nm,
            };
          }),
          cust: user?.cust.map((list) => {
            return {
              cust_cd: list.cust_cd,
              cust_div: list.cust_div,
              cust_nm: list.cust_nm,
            };
          }),
          dept: user?.dept?.map((list) => {
            return {
              dept_cd: list.dept_cd,
              deal_div: list.deal_div,
              dept_nm: list.dept_nm,
            };
          }),
        });
    },
  },
};
</script>

<style lang="scss">
#app {
  position: relative;
}
.slide-right-enter-active,
.slide-right-leave-active {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(0);
  transition: transform 0.5s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: transform 0.5s ease;
}
</style>
