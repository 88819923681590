import { ipgoexp } from ".";
/* 오더등록및조회(마감및취소) */

//출고구분조회
function getIpgoCode() {
  return ipgoexp.post("ipgo_gb.seize", {});
}



//주문생성목록(마감전/마감완료)
function ipgoExpList(item) {
  return ipgoexp.post("ipgoexp_list.seize", item);
}

//주문생성목록(마감전/마감완료)
function ipgoExpDetail(item) {
  return ipgoexp.post("ipgoexp_detail_list.seize", item);
}


//주문생성목록(마감전/마감완료)
function ipgoExpInsert(item) {
  return ipgoexp.post("ipgoexp_insert.seize", item);
}

//주문생성목록(마감전/마감완료)
function ipgoExpUpdate(item) {
  return ipgoexp.post("ipgoexp_update.seize", item);
}

//주문생성목록(마감전/마감완료)
function ipgoExpDelete(item) {
  return ipgoexp.post("ipgoexp_delete.seize", item);
}

export {
  getIpgoCode,
  ipgoExpList,
  ipgoExpDetail,
  ipgoExpInsert,
  ipgoExpUpdate,
  ipgoExpDelete
};
